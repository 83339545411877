<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        :max-width="editedItem.bylist == '1' ? '1600px' : '1000px'"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>

            <v-spacer></v-spacer>
            <v-layout row justify-center align-center v-if="progress">
              <v-progress-circular
                indeterminate
                :size="30"
                :width="7"
                color="purple"
              ></v-progress-circular>
            </v-layout>

            <v-btn
              color="blue darken-1"
              @click="save"
              :disabled="save_disable"
              class="mx-2"
              small
            >
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" @click.stop="close" small>
              Annuler
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="6" md="12">
                  <v-autocomplete
                    v-model="editedItem.produit_id"
                    :item-value="'produit_id'"
                    :items="products_list"
                    :filter="customFilter"
                    label="Produit"
                    :readonly="!modify || form_list.length > 0"
                    @change="produit_change"
                    :rules="[(v) => !!v || 'Produit obligatoire']"
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{
                        (item.product_ref
                          ? "(" + item.product_ref + ")-"
                          : "") +
                        (item.product_code ? item.product_code + "-" : "") +
                        item.product_name
                      }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="
                            (item.product_code ? item.product_code + '-' : '') +
                            item.product_name
                          "
                        ></v-list-item-title>
                        <v-list-item-subtitle v-if="item.product_ref">{{
                          "Ref.: " + item.product_ref
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-autocomplete
                    v-model="editedItem.article_id"
                    :item-value="'article_id'"
                    :items="articles_list"
                    :filter="customFilter"
                    label="Article"
                    :readonly="!modify || form_list.length > 0"
                    @change="article_change"
                    :rules="[(v) => !!v || 'Article obligatoire']"
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{
                        (item.ref_ext ? "(" + item.ref_ext + ")-" : "") +
                        (item.code ? item.code + "-" : "") +
                        item.label
                      }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="item.label"
                        ></v-list-item-title>
                        <v-list-item-title
                          v-html="'Code : ' + item.code"
                        ></v-list-item-title>
                        <v-list-item-subtitle v-if="item.ref_ext">{{
                          "Ref. Ext : " + item.ref_ext
                        }}</v-list-item-subtitle>
                        <v-list-item-subtitle>{{
                          "Fournisseur : " + item.tier_name
                        }}</v-list-item-subtitle>
                        <v-list-item-subtitle
                          v-html="
                            'Qte Sortie : ' +
                            (item.qte_liv ? item.qte_liv : 0) +
                            ' ' +
                            (item.unit ? item.unit : '')
                          "
                        ></v-list-item-subtitle>
                        <v-list-item-subtitle
                          v-html="
                            'Qte Retournée : ' +
                            (item.qte_ret ? item.qte_ret : 0) +
                            ' ' +
                            (item.unit ? item.unit : '')
                          "
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  v-if="
                    !editedItem.pu ||
                    $store.state.auth.includes('01027') ||
                    $store.state.isadmin
                  "
                >
                  <v-text-field
                    autocomplete="off"
                    class="inputPrice"
                    type="number"
                    v-model.number="editedItem.pu"
                    readonly
                    label="P.U."
                    :rules="[(v) => !!v || 'Prix obligatoire']"
                    dense
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="4" md="6">
                  <v-autocomplete
                    ref="mvmto"
                    v-model="mvm_to"
                    item-value="id"
                    item-text="label"
                    :items="depots"
                    :readonly="!modify || form_list.length > 0"
                    label="Depot de Destination"
                    @change="depot_change"
                    :rules="[
                      (v) => !!v || 'Depot obligatoire',
                      (v) =>
                        ligne_exist(editedItem.article_id, v) == false ||
                        'Ligne existe!',
                    ]"
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        autocomplete="off"
                        ref="qte"
                        class="inputPrice"
                        type="number"
                        dense
                        v-model.number="editedItem.qte"
                        :label="
                          'Quantité' +
                          (editedItem.unit ? ' (' + editedItem.unit + ')' : '')
                        "
                        :rules="[
                          (v) => !!v || 'Quantité obligatoire',
                          (v) => v > 0 || 'Valeur incorrecte ',
                          (v) =>
                            parseFloat(v) <= parseFloat(qte_max) ||
                            !modify ||
                            'Maximum autorisé ' + qte_max,
                        ]"
                        v-bind="attrs"
                        v-on="on"
                        :readonly="
                          editedItem.bylist == '1' ||
                          (editedItem.bylist == '0' && depot.NbRack > 0) ||
                          !modify
                        "
                        @keydown.enter="save"
                        hide-spin-buttons
                        :disabled="!mvm_to || save_disable"
                        @focus="$event.target.select()"
                      ></v-text-field>
                    </template>

                    <li v-if="qte_ret">
                      {{
                        "Quantité Retournée " +
                        +numberWithSpace(qte_ret.toFixed(precision))
                      }}
                    </li>
                  </v-tooltip>
                </v-col>

                <v-col cols="12" sm="1" md="12">
                  <v-text-field
                    autocomplete="off"
                    ref="comment"
                    dense
                    v-model="editedItem.comment"
                    label="Commentaire"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="1"
                  md="6"
                  v-if="
                    editedItem.bylist == '1' &&
                    editedItem.article_id > 0 &&
                    mvm_to
                  "
                >
                  <v-row>
                    <v-col cols="12" sm="1" md="11">
                      <listitems
                        :list="flist"
                        :headers="colisheaders"
                        :title="'Disponible'"
                        :master="true"
                        :add="false"
                        :del="false"
                        :Update="false"
                        :showstd="true"
                        :showedit="true"
                        :Total="true"
                        :print="true"
                        :exp_excel="true"
                        :imp_excel="false"
                        :key="kflist"
                        :multiple="true"
                        @selected_rows="selected_left_change"
                        :laoding="productload"
                        :ipg="5"
                      >
                      </listitems>
                    </v-col>
                    <v-col cols="12" sm="1" md="1">
                      <v-btn
                        fab
                        color="blue darken-1"
                        @click="add_product"
                        class="my-2"
                        small
                        :disabled="selected_left.length == 0"
                      >
                        >
                      </v-btn>

                      <v-btn
                        fab
                        color="blue darken-1"
                        @click="del_product"
                        class="my-2"
                        small
                        :disabled="selected_right.length == 0"
                      >
                        <span v-html="'<'"></span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  :sm="editedItem.bylist == '1' ? 6 : 12"
                  :md="editedItem.bylist == '1' ? 6 : 12"
                  v-if="
                    (editedItem.bylist == '1' ||
                      (editedItem.bylist == '0' && depot.NbRack > 0)) &&
                    editedItem.article_id > 0 &&
                    mvm_to
                  "
                >
                  <listitems
                    :list="form_list"
                    :headers="
                      editedItem.bylist == '1' ? colisheaders : boxheaders
                    "
                    :title="list_title"
                    :master="true"
                    :add="editedItem.bylist == '0'"
                    :del="false"
                    :Update="true"
                    :showstd="true"
                    :Total="true"
                    :print="true"
                    :exp_excel="true"
                    :imp_excel="false"
                    :key="frml"
                    @delete="del_item"
                    :multiple="editedItem.bylist == '1'"
                    @selected_rows="selected_right_change"
                    :laoding="listload"
                    :ipg="5"
                  >
                  </listitems>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-form>

    <confirmdialog ref="confirm" />
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}
const confirmdialog = () => import("./ConfirmDialog.vue");
const listitems = () => import("./ListItems.vue");
import MVM_DETAIL_UPDATE from "../graphql/Mouvment/MVM_DETAIL_UPDATE.gql";
import PRODUCT_LIST_MVM from "../graphql/Product/PRODUCT_LIST_MVM.gql";
//import PRODUCT_LIST_INPUT from "../graphql/Product/PRODUCT_LIST_INPUT.gql";

export default {
  components: { confirmdialog, listitems },
  name: "mvmretform",
  props: {
    item: Object,
    items: Array,
    showForm: Boolean,
    mvm_type: Number,
    attributes: Array,
    articles: Array,
    order: Object,
    mvm: Object,
    modify: Boolean,
    boxs: Array,
  },
  data: () => ({
    kflist: 100,
    adding: false,
    deleting: false,
    productload: false,
    save_disable: false,
    imp: false,
    title: "Liste Détaillée",
    listload: false,
    isListClose: true,
    list: false,
    datepicker1: false,
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    defaultItem: {
      id: -1,
    },
    editedItem: {},
    depot: {},
    combovalue: "",
    isProductClosed: true,
    liv: {},

    qte: 0,
    qte_liv: 0,
    qte_ret: 0,
    qte_rst: 0,
    qte_max: 0,
    qte_depot: 0,
    tol: 0,
    mvm_to: "",
    precision: 2,
    list_title: "Articles",
    colisheaders2: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Index",
        value: "code",
        selected: true,
      },
      {
        text: "Code",
        value: "ref",
        hiden: true,
        selected: true,
      },
      {
        text: "Quantité",
        value: "qte_rest",
        total: "qte_rest",
        slot: "href22",
        align: "end",
        selected: true,
        edit: true,
        type: "qte",
        dec: 2,
        sm: 3,
        md: 3,
        rules: [(v) => !!v || "Quantité obligatoire"],
      },
      {
        text: "Date de péremption",
        value: "expire_date",
        selected: true,
        slot: "date",
        hiden: true,
      },
      {
        text: "Origine",
        value: "origin",
        selected: true,
      },
      {
        text: "Box",
        value: "box_id",
        slot: "simple-select",
        selected: true,
        edit: false,
        hiden: true,
        type: "autocomplete",
        item_value: "id",
        item_text: "box",
        subtitle1: "rack",
        subtitle1_label: "Rack",
        subtitle2: "stock_cmpt",
        subtitle2_label: "Stock : ",
        subtitle3: "nbarticle",
        subtitle3_label: "Nb : ",
        select_list: [],
        show_list: [],
        sm: 6,
        md: 6,
        rules: [(v) => !!v || "Box obligatoire"],
      },
      {
        text: "Comment",
        value: "comment",
        selected: true,
        edit: true,
        type: "text",
        sm: 12,
        md: 12,
      },
    ],
    boxheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },

      {
        text: "Box",
        value: "box_id",
        slot: "simple-select",
        selected: true,
        edit: true,
        type: "autocomplete",
        item_value: "id",
        item_text: "box",
        subtitle1: "rack",
        subtitle1_label: "Rack",
        subtitle2: "stock_cmpt",
        subtitle2_label: "Stock : ",
        subtitle3: "nbarticle",
        subtitle3_label: "Nb : ",
        select_list: [],
        show_list: [],
        sm: 6,
        md: 6,
        rules: [(v) => !!v || "Box obligatoire"],
      },
      {
        text: "Quantité",
        value: "qte",
        total: "qte",
        slot: "href22",
        align: "end",
        selected: true,
        edit: true,
        type: "number",
        dec: 2,
        sm: 3,
        md: 3,
        rules: [(v) => !!v || "Quantité obligatoire"],
      },
    ],
    colisheaders: [],
    form_list: [],
    prdslist: [],
    selected_left: [],
    selected_right: [],
    frml: 600,
    cs: 0,
  }),

  computed: {
    depots() {
      return this.$store.state.depots.filter(
        (elm) => elm.ChildCount == 0 && elm.type_id == 2
      );
    },
    products_list() {
      let list = [];
      if (this.articles) list = this.articles;

      return list;
    },
    articles_list() {
      let list = [];
      if (this.products_list) {
        list = this.products_list.filter(
          (elm) =>
            (elm.produit_id == this.editedItem.produit_id &&
              !this.items
                .map((elm2) => elm2.article_id)
                .includes(elm.article_id)) ||
            this.editedItem.id > 0
        );
      }

      return list;
    },
    boxs_list() {
      let list = [];
      if (this.boxs)
        list = this.boxs.filter((elm) => elm.depot_id == this.mvm_to);
      return list;
    },
    flist() {
      let l = [];
      if (this.prdslist)
        l = this.prdslist.filter(
          (elm) => !this.form_list.map((elm3) => elm3.id).includes(elm.id)
        );
      return l;
    },
    sumlist_selected() {
      let list = [];
      list = this.form_list ? this.form_list : [];
      return list
        ? parseFloat(
            list
              .reduce((a, b) => a + (b["qte"] || 0), 0)
              .toFixed(this.precision)
          )
        : 0;
    },

    attributs_list() {
      return this.attributes.filter((elm) => elm.type_attr == "colis");
    },
    showDetail() {
      return this.showForm;
    },
    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouvelle Ligne "
          : "Ligne N° ".concat(parseInt(this.item.index) + 1);
      else return "";
    },
  },
  watch: {
    form_list: {
      handler() {
        if (
          this.editedItem.bylist == "1" ||
          (this.editedItem.bylist == "0" && this.depot.NbRack > 0)
        )
          this.editedItem.qte = this.sumlist_selected.toFixed(this.precision);
      },
      deep: true,
    },
  },

  created() {},
  async mounted() {
    if (this.item) {
      this.editedItem = Object.assign({}, this.defaultItem);
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        this.mvm_to = this.editedItem.depot_id;
        this.article_change();
        this.depot_change();
      } else {
        if (this.depots.length == 1) {
          this.mvm_to = this.depots[0].id;
          this.depot_change();
        }
      }
    }
    this.colisheaders2[1].hiden = this.$store.state.options[0].code_list == "1";
    this.colisheaders2[2].hiden = this.$store.state.options[0].code_list == "0";
    this.colisheaders2[3].value = "qte";
    this.colisheaders2[3].total = "qte";
  },

  methods: {
    produit_change() {
      var unique = this.articles_list
        .map((elm) => elm.article_id)
        .filter(onlyUnique);
      if (unique.length == 1 && this.editedItem.id < 0) {
        this.editedItem.article_id = unique[0];
        this.article_change();
      }
    },
    customFilter(item, queryText) {
      let textlist = [];
      ["ref", "code", "label", "nomvm", "nobon", "opr_date", "mvm_ref"].forEach(
        (element) => {
          const text1 = item[element] ? item[element].toLowerCase() : "";
          textlist.push(text1);
        }
      );

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },

    async depot_change() {
      this.form_list = [];
      if (this.editedItem.id < 0) {
        this.editedItem.qte = 0;
        this.$refs.form.resetValidation();
      }
      let i = this.depots.findIndex((elm) => elm.id == this.mvm_to);

      if (i >= 0) {
        this.depot = this.depots[i];

        this.list_title =
          this.editedItem.bylist == "1" ? "Articles" : this.depot.box;
        this.colisheaders2[6].text = this.depot.box ? this.depot.box : "Box";
        this.colisheaders2[6].rules = [
          (v) => !!v || this.colisheaders2[6].text + " obligatoire",
        ];
        this.colisheaders2[6].subtitle1_label = this.depot.rack
          ? this.depot.rack
          : "Rack";
        this.colisheaders2[6].hiden = this.depot.NbRack == 0;
        this.colisheaders2[6].edit = this.depot.NbRack > 0;
        this.colisheaders2[6].select_list = this.boxs_list;
        this.colisheaders2[6].show_list = this.boxs_list;

        let u = "";
        let z;
        if (this.units)
          z = this.units.findIndex((elm) => elm.id == this.editedItem.fk_unit);
        if (z >= 0) u = "(" + this.units[z].name + ")";
        this.boxheaders[1].text = this.colisheaders2[6].text;
        this.boxheaders[1].rules = this.colisheaders2[6].rules;

        this.boxheaders[1].subtitle1_label = this.depot.rack
          ? this.depot.rack
          : "Rack";

        this.boxheaders[1].show_list = this.boxs_list;
        this.boxheaders[2].text = "Quantité " + u;
        this.boxheaders[2].dec = this.precision;
        if (this.editedItem.bylist == "1") this.init_list();
        if (
          this.editedItem.bylist == "0" &&
          this.depot.NbRack > 0 &&
          this.editedItem.id > 0
        ) {
          this.form_list = this.editedItem.boxs;
          this.frml++;
        }
      }
      //this.$refs.form.resetValidation();
      this.boxheaders[1].select_list = this.boxs_list.filter(
        (elm) => !this.form_list.map((elm) => elm.box_id).includes(elm.id)
      );
      this.frml++;
    },
    selected_left_change(items) {
      this.selected_left = items;
    },
    selected_right_change(items) {
      this.selected_right = items;
    },
    add_product() {
      if (this.selected_left.length > 0) {
        this.selected_right = [];
        this.selected_left.forEach((element) => {
          element.selected = false;
          this.form_list.unshift(element);
        });
        this.selected_left = [];
        this.editedItem.qte = this.sumlist_selected.toFixed(this.precision);
        this.frml++;
        this.kflist++;
      }
    },

    del_product() {
      if (this.selected_right) {
        this.selected_left = [];
        this.selected_right.forEach((element) => {
          element.selected = false;
          this.form_list.splice(
            this.form_list.findIndex((elm) => elm.id == element.id),
            1
          );
        });
        this.selected_right = [];
        this.editedItem.qte = this.sumlist_selected.toFixed(this.precision);
        this.frml++;
        this.kflist++;
      }
    },
    del_item() {
      this.editedItem.qte = this.sumlist_selected.toFixed(this.precision);
    },

    init_list() {
      this.colisheaders = [];
      this.colisheaders2[4].hiden = this.editedItem.is_expire == "0";
      this.colisheaders2.forEach((element) => {
        this.colisheaders.push(element);
      });
      this.colisheaders[3].text = this.editedItem.unit_name;
      this.colisheaders[3].rules = [
        (v) => !!v || this.editedItem.unit_name + " obligatoire",
      ];
      this.colisheaders[3].dec = this.precision;
      let colis_attributs = [];

      colis_attributs = this.editedItem.ppattributs
        ? this.editedItem.ppattributs.split(",")
        : [];

      for (let index = 0; index < colis_attributs.length; index++) {
        const element = colis_attributs[index];

        let i = this.attributs_list.findIndex((elm) => elm.id == element);
        if (i >= 0) {
          let ls = [];
          this.attributs_list[i].attributvalues.forEach((element) => {
            ls.push(element.name);
          });
          let elm = {
            text: this.attributs_list[i].caption
              ? this.attributs_list[i].caption
              : this.attributs_list[i].name,
            value: "ref" + index,
            selected: true,
            edit: true,
            readonly: false,
            rules: [
              (v) =>
                !!v ||
                (this.attributs_list[i].caption
                  ? this.attributs_list[i].caption
                  : this.attributs_list[i].name) + " obligatoire",
            ],
            key: this.attributs_list[i].key,
            type:
              this.attributs_list[i].attributvalues.length > 0
                ? "autocomplete"
                : "text",
            select_list: ls,
            show_list: ls,
            sm: 6,
            md: 6,
          };
          this.colisheaders.splice(index + 2, 0, elm);
        }
      }
    },
    async load_product_list() {
      //list possible

      this.productload = true;
      let v;
      let r;

      v = {
        article_id: [this.editedItem.article_id],
        tier_id: this.mvm.tier_id,
        mvm_type: 15,
        stock_id: 0,
      };
      r = await this.requette(PRODUCT_LIST_MVM, v);
      if (r) this.prdslist = r.product_list_mvm;

      this.productload = false;
      this.kflist++;
    },
    async list_mvm() {
      this.form_list = [];

      if (this.editedItem.id >= 0) {
        this.listload = true;
        let v = {
          mvm_detail_id: this.editedItem.id,
        };

        let r = await this.requette(PRODUCT_LIST_MVM, v);
        if (r) {
          this.form_list = r.product_list_mvm;
          this.frml++;
          this.editedItem.qte = this.sumlist_selected.toFixed(this.precision);
          this.listload = false;
        }
      }
    },

    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) {
            this.$store.dispatch("Changed", true);
            this.$emit("change");
          }
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },

    datefr(date, time = false, long = true) {
      let t = "";
      let option = {
        year: "numeric",
        month: long ? "long" : "numeric",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },
    async requette(query, v) {
      this.loading = true;
      let r = [];

      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    ligne_exist(p, d) {
      let i = this.items.filter((elm) => elm.id != this.item.id);
      let e = -1;
      if (p && d)
        e = i.findIndex((elm) => elm.article_id == p && elm.depot_id == d);
      return e != -1;
    },

    async article_change() {
      if (this.articles_list) {
        let qte_tmp = 0;
        this.items.forEach((element) => {
          if (element.article_id == this.editedItem.article_id)
            qte_tmp = qte_tmp + parseFloat(element.qte);
        });
        let i = this.articles_list.findIndex(
          (elm) => elm.article_id == this.editedItem.article_id
        );
        if (i >= 0) {
          this.editedItem.fk_unit = this.articles_list[i].fk_unit;
          this.editedItem.unit_name = this.articles_list[i].unit_name;
          this.editedItem.is_expire = this.articles_list[i].is_expire;
          this.editedItem.unit = this.articles_list[i].unit;

          this.qte_ret = this.articles_list[i].qte_ret;
          this.editedItem.qte_ret = this.articles_list[i].qte_ret;
          if (this.editedItem.id < 0) {
            this.editedItem.qte = 0;
            this.$refs.form.resetValidation();
          }
          this.editedItem.pu = this.articles_list[i].pmp;
          this.editedItem.bylist = this.articles_list[i].bylist;
          this.precision = this.articles_list[i].decimal;
          this.editedItem.ppattributs = this.articles_list[i].ppattributs;
          this.editedItem.unit_name = this.articles_list[i].unit_name;
          this.editedItem.product_name = this.articles_list[i].label;
          this.editedItem.pmp = this.articles_list[i].pmp;
          this.editedItem.tva_tx = this.articles_list[i].tva_tx;

          this.qte = this.articles_list[i].qte_liv;

          this.editedItem.fk_unit = this.articles_list[i].fk_unit;
          if (this.articles_list[i].depot_id && this.editedItem.id < 0) {
            this.mvm_to = this.articles_list[i].depot_id;
          }
          this.qte_max =
            this.qte +
            (this.editedItem.qte ? this.editedItem.qte : 0) -
            qte_tmp -
            this.editedItem.qte_ret;
        }
        if (this.editedItem.bylist == "1" && this.modify) {
          if (this.editedItem.id > 0) await this.list_mvm();
          await this.load_product_list();
        } else if (this.$refs.qte) this.$refs.qte.focus();
      }
    },
    async close() {
      this.$emit("close", this.items);
    },
    verifie_attribut() {
      let ok = true;
      let l1 =
        this.editedItem.bylist == "1" ? this.colisheaders : this.boxheaders;
      let l = l1.filter((elm) => elm.edit == true && elm.hiden != true);
      for (let index = 0; index < this.form_list.length; index++) {
        const element = this.form_list[index];

        for (let i = 0; i < l.length; i++) {
          if (!element[l[i].value] && l[i].value != "comment") {
            this.snackbar_text =
              l[i].text + " manquant! Ligne " + (parseInt(index) + 1);
            this.snackbar_color = "error";
            this.snackbar = true;
            ok = false;
            break;
          }
        }
        if (ok && this.editedItem.is_expire == "1" && !element.expire_date) {
          this.snackbar_text =
            "Date péremption manquante! Ligne " + (parseInt(index) + 1);
          this.snackbar_color = "error";
          this.snackbar = true;
          ok = false;
          break;
        }
      }
      return ok;
    },
    async save() {
      if (this.$refs.form.validate()) {
        // let ok = true;
        // ok = this.verifie_attribut();
        //   if (ok) {
        this.save_disable = true;
        let pl = [];
        let products_list = [];
        let boxs = [];
        if (this.editedItem.bylist == "1") {
          let elm = {};
          this.form_list.forEach((element) => {
            elm = {
              fk_produit_list: element.id,
              qte: element.qte,
            };
            products_list.push(elm);

            pl.push({
              id: element.id,
              ref: element.ref,
              ref0: element.ref0,
              ref1: element.ref1,
              ref2: element.ref2,
              ref3: element.ref3,
              ref4: element.ref4,
              ref5: element.ref5,
              transf_box_id: element.box_id,
            });
          });
        }
        if (this.editedItem.bylist == "0" && this.depot.NbRack > 0) {
          for (let index = 0; index < this.form_list.length; index++) {
            const element = this.form_list[index];
            let elm = {};
            elm.article_id = this.editedItem.article_id;
            elm.box_id = element.box_id;
            elm.qte = element.qte;

            boxs.push(elm);
            //
          }
        }
        let i;
        i = this.articles_list.findIndex(
          (elm) => elm.id == this.editedItem.article_id
        );
        if (i >= 0) {
          this.editedItem.product_name = this.articles_list[i].label;
        }
        let v;
        v = {
          mvmdetail: [
            {
              mvm_id: this.item.mvm_id,
              pu: this.editedItem.pu,
              qte: parseFloat(this.editedItem.qte),
              tva_tx: this.editedItem.tva_tx,
              fk_unit: this.editedItem.fk_unit,
              description: this.editedItem.description,
              depot_id: this.mvm_to,

              article_id: this.editedItem.article_id,
              comment: this.editedItem.comment,
              write_uid: this.$store.state.me.id,
            },
          ],
          factor: this.editedItem.factor,
          id: this.editedItem.id > 0 ? this.editedItem.id : null,
          products_list: products_list.length > 0 ? products_list : null,
          pl: pl.length > 0 ? pl : null,
          boxs: boxs.length > 0 ? boxs : null,
        };
        let r = await this.maj(MVM_DETAIL_UPDATE, v);
        if (r) {
          if (this.editedItem.id > 0)
            this.items.splice(this.item.index, 1, this.editedItem);
          else {
            this.editedItem.id = r.UpdateMvmDetail;
            this.items.push(this.editedItem);
          }
          this.$emit("change", this.editedItem);
          this.$store.dispatch("Changed", true);
          this.$emit("close", this.items);
        } else {
          this.snackbar_text = " erreur d'enregistrement";
          this.snackbar_color = "error";
          this.snackbar = true;
        }
        this.save_disable = false;
        //   }
      }
    },
  },
};
</script>
